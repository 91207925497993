<template>
  <div class="contents register img">
    <div class="title flexB">
      <h2>공지사항 관리</h2>
    </div>
    <section class="box one">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            공지사항 제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL double">
          <p class="bold">
            공지사항 내용
            <span>*</span>
          </p>
          <textarea v-model="contents" />
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/notice/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import {
  createNotice,
  fetchNotice,
  updateNotice,
  removeNotice,
} from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      title: "",
      contents: "",
      currentPage: 1,
      total: 0,
      updatedAt: "",
      createdAt: "",
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.noticeId = this.$route.query.id;
      this.getNotice();
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    getNotice() {
      fetchNotice(this.noticeId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.contents = res.data.data.contents;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    handleDelete() {
      let result = confirm("삭제 하시겠습니까?");
      if (!result) {
        return;
      }
      removeNotice(this.noticeId).then((res) => {
        if (res.data.status == 200) {
          alert("공지사항 정보가 삭제 되었습니다.");
          this.$router.replace("/admin/notice/manage");
        }
      });
    },
    submit() {
      if (this.title == "") {
        return alert("공지사항 제목을 입력해 주세요.");
      } else if (this.contents == "") {
        return alert("공지사항 내용을 입력해 주세요.");
      }
      let data = {
        noticeId: this.noticeId,
        title: this.title,
        contents: this.contents,
      };

      if (this.editMode) {
        updateNotice(data).then((res) => {
          if (res.data.status == 200) {
            alert("공지사항 정보가 반영 되었습니다.");
            this.$router.push("/admin/notice/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        createNotice(data).then((res) => {
          if (res.data.status == 200) {
            alert("공지사항 등록이 완료 되었습니다.");
            this.$router.push("/admin/notice/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>
